import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext'; 
import { MenuProvider } from './contexts/MenuContext'; 
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import PrivateRoute from './components/PrivateRoute';
import DashboardPage from './pages/DashboardPage';
import ToponlinePage from './pages/toponline/ToponlinePage';
import ToponlineUsuariosPage from './pages/toponline/UsuariosPage';
import ToponlineUsuarioPage from './pages/toponline/UsuarioPage';
import ToponlineEmpresaInformacionPage from './pages/toponline/EmpresaInformacion';
import ToponlineEstadosFinancierosPdfPage from './pages/toponline/EstadosFinancierosPdfPage';
import ToponlineKioskoPage from './pages/toponline/KioskoPage';
import ToponlineKioskoSeccionesPage from './pages/toponline/KioskoSeccionesPage';
import ToponlineKioskoCategoriasPage from './pages/toponline/KioskoCategoriasPage';
import ToponlineKioskoRolesPage from './pages/toponline/KioskoRolesPage';
import ToponlineBasesPage from './pages/toponline/BasesPage';
import ToponlineNotificacionesPage from './pages/toponline/NotificacionesPage';
import ToponlineNoticiasPage from './pages/toponline/NoticiasPage';
import ToponlineBannersPage from './pages/toponline/BannersPage';
import ToponlinePodcastPage from './pages/toponline/PodcastPage';
import ToponlineContenidosPage from './pages/toponline/ContenidosPage';
import ToponlineContenidoPage from './pages/toponline/ContenidoPage';
import ToponlineMenusPage from './pages/toponline/MenusPage';
import ToponlineAnalyticsPage from './pages/toponline/AnalyticsPage';
import MigracionUpdatePasswordsPage from './pages/utilidades/MigracionUpdatePasswordsPage';
import MinasPage from './pages/minas/MinasPage';
import MinasUsuariosPage from './pages/minas/UsuariosPage';
import MinasUsuarioPage from './pages/minas/UsuarioPage';
import MinasNoticiasPage from './pages/minas/NoticiasPage';
import MinasPerfilesPage from './pages/minas/PerfilesPage';
import MinasPerfilPage from './pages/minas/PerfilPage';
import MinasPerfilMapaPage from './pages/minas/PerfilMapaPage';
import MinasPerfilGaleriaPage from './pages/minas/PerfilGaleriaPage';
import MinasMinasPage from './pages/minas/MinasMinasPage';
import MinasMinaPage from './pages/minas/MinasMinaPage';
import MinasMinaMapaPage from './pages/minas/MinasMinaMapaPage';
import MinasBannersPage from './pages/minas/BannersPage';
import PymesPage from './pages/pymes/PymesPage';
import PymesUsuariosPage from './pages/pymes/UsuariosPage';
import PymesUsuarioPage from './pages/pymes/UsuarioPage';
import PymesNoticiasPage from './pages/pymes/NoticiasPage';
import PymesBannersPage from './pages/pymes/BannersPage';
import InfraestructuraPage from './pages/infraestructura/InfraestructuraPage';
import InfraestructuraUsuariosPage from './pages/infraestructura/UsuariosPage';
import InfraestructuraUsuarioPage from './pages/infraestructura/UsuarioPage';
import InfraestructuraNoticiasPage from './pages/infraestructura/NoticiasPage';
import InfraestructuraBannersPage from './pages/infraestructura/BannersPage';
import InfraestructuraPerfilesPage from './pages/infraestructura/PerfilesPage';
import InfraestructuraPerfilPage from './pages/infraestructura/PerfilPage';
import InfraestructuraPerfilMapaPage from './pages/infraestructura/PerfilMapaPage';
import InfraestructuraPerfilGaleriaPage from './pages/infraestructura/PerfilGaleriaPage';

// Importa otros componentes según sea necesario

function App() {
    return (
        <MenuProvider>
            <UserProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/dashboard" element={<PrivateRoute element={<DashboardPage />} />} />

                        {/* Define otras rutas privadas aquí */} 
                        {/* <Route path="/another-private-route" element={<PrivateRoute element={<AnotherComponent />} />} /> */}
                        <Route path="/toponline" element={<PrivateRoute element={<ToponlinePage />} />} />
                        <Route path="/toponline-usuarios" element={<PrivateRoute element={<ToponlineUsuariosPage />} />} />
                        <Route path="/toponline-usuario" element={<PrivateRoute element={<ToponlineUsuarioPage />} />} />
                        <Route path="/toponline-usuario/:userId" element={<PrivateRoute element={<ToponlineUsuarioPage />} />} />
                        <Route path="/toponline-empresa-informacion" element={<PrivateRoute element={<ToponlineEmpresaInformacionPage />} />} />
                        <Route path="/toponline-empresa-informacion/:rucDni" element={<PrivateRoute element={<ToponlineEmpresaInformacionPage />} />} />
                        <Route path="/toponline-estados-financieros-pdf" element={<PrivateRoute element={<ToponlineEstadosFinancierosPdfPage />} />} />
                        <Route path="/toponline-kiosko" element={<PrivateRoute element={<ToponlineKioskoPage />} />} />
                        <Route path="/toponline-kiosko-secciones/:id" element={<PrivateRoute element={<ToponlineKioskoSeccionesPage />} />} />
                        <Route path="/toponline-kiosko-categorias" element={<PrivateRoute element={<ToponlineKioskoCategoriasPage />} />} />
                        <Route path="/toponline-kiosko-roles" element={<PrivateRoute element={<ToponlineKioskoRolesPage />} />} />
                        <Route path="/toponline-bases" element={<PrivateRoute element={<ToponlineBasesPage />} />} />
                        <Route path="/toponline-notificaciones" element={<PrivateRoute element={<ToponlineNotificacionesPage />} />} />
                        <Route path="/toponline-noticias" element={<PrivateRoute element={<ToponlineNoticiasPage />} />} />
                        <Route path="/toponline-banners" element={<PrivateRoute element={<ToponlineBannersPage />} />} />
                        <Route path="/toponline-podcast" element={<PrivateRoute element={<ToponlinePodcastPage />} />} />
                        <Route path="/toponline-contenidos" element={<PrivateRoute element={<ToponlineContenidosPage />} />} />
                        <Route path="/toponline-contenido" element={<PrivateRoute element={<ToponlineContenidoPage />} />} />
                        <Route path="/toponline-contenido/:contenidoId" element={<PrivateRoute element={<ToponlineContenidoPage />} />} />
                        <Route path="/toponline-menus" element={<PrivateRoute element={<ToponlineMenusPage />} />} />
                        <Route path="/toponline-analytics" element={<PrivateRoute element={<ToponlineAnalyticsPage />} />} />
                        <Route path="/utilidades-migracion-update-passwords" element={<PrivateRoute element={<MigracionUpdatePasswordsPage />} />} />
                        <Route path="/minas" element={<PrivateRoute element={<MinasPage />} />} />
                        <Route path="/minas-usuarios" element={<PrivateRoute element={<MinasUsuariosPage />} />} />
                        <Route path="/minas-usuario" element={<PrivateRoute element={<MinasUsuarioPage />} />} />
                        <Route path="/minas-usuario/:userId" element={<PrivateRoute element={<MinasUsuarioPage />} />} />
                        <Route path="/minas-noticias" element={<PrivateRoute element={<MinasNoticiasPage />} />} />
                        <Route path="/minas-perfiles" element={<PrivateRoute element={<MinasPerfilesPage />} />} />
                        <Route path="/minas-perfil" element={<PrivateRoute element={<MinasPerfilPage />} />} />
                        <Route path="/minas-perfil/:perfilId" element={<PrivateRoute element={<MinasPerfilPage />} />} />
                        <Route path="/minas-perfil-mapa/:perfilId" element={<PrivateRoute element={<MinasPerfilMapaPage />} />} />
                        <Route path="/minas-perfil-galeria/:perfilId" element={<PrivateRoute element={<MinasPerfilGaleriaPage />} />} />
                        <Route path="/minas-minas" element={<PrivateRoute element={<MinasMinasPage />} />} />
                        <Route path="/minas-mina" element={<PrivateRoute element={<MinasMinaPage />} />} />
                        <Route path="/minas-mina/:minaId" element={<PrivateRoute element={<MinasMinaPage />} />} />
                        <Route path="/minas-mina-mapa/:minaId" element={<PrivateRoute element={<MinasMinaMapaPage />} />} />
                        <Route path="/minas-banners" element={<PrivateRoute element={<MinasBannersPage />} />} />
                        <Route path="/pymes" element={<PrivateRoute element={<PymesPage />} />} />
                        <Route path="/pymes-usuarios" element={<PrivateRoute element={<PymesUsuariosPage />} />} />
                        <Route path="/pymes-usuario" element={<PrivateRoute element={<PymesUsuarioPage />} />} />
                        <Route path="/pymes-usuario/:userId" element={<PrivateRoute element={<PymesUsuarioPage />} />} />
                        <Route path="/pymes-noticias" element={<PrivateRoute element={<PymesNoticiasPage />} />} />
                        <Route path="/pymes-banners" element={<PrivateRoute element={<PymesBannersPage />} />} />
                        <Route path="/infraestructura" element={<PrivateRoute element={<InfraestructuraPage />} />} />
                        <Route path="/infraestructura-usuarios" element={<PrivateRoute element={<InfraestructuraUsuariosPage />} />} />
                        <Route path="/infraestructura-usuario" element={<PrivateRoute element={<InfraestructuraUsuarioPage />} />} />
                        <Route path="/infraestructura-usuario/:userId" element={<PrivateRoute element={<InfraestructuraUsuarioPage />} />} />
                        <Route path="/infraestructura-noticias" element={<PrivateRoute element={<InfraestructuraNoticiasPage />} />} />
                        <Route path="/infraestructura-banners" element={<PrivateRoute element={<InfraestructuraBannersPage />} />} />
                        <Route path="/infraestructura-perfiles" element={<PrivateRoute element={<InfraestructuraPerfilesPage />} />} />
                        <Route path="/infraestructura-perfil" element={<PrivateRoute element={<InfraestructuraPerfilPage />} />} />
                        <Route path="/infraestructura-perfil/:perfilId" element={<PrivateRoute element={<InfraestructuraPerfilPage />} />} />
                        <Route path="/infraestructura-perfil-mapa/:perfilId" element={<PrivateRoute element={<InfraestructuraPerfilMapaPage />} />} />
                        <Route path="/infraestructura-perfil-galeria/:perfilId" element={<PrivateRoute element={<InfraestructuraPerfilGaleriaPage />} />} />
                        <Route path="/logout" element={<PrivateRoute element={<LogoutPage />} />} />
                        
                        {/* Redirige a la página de login si ninguna otra ruta coincide */}
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    </Routes>
                </Router>
            </UserProvider>
        </MenuProvider>
    );
}

export default App;
