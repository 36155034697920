import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import LayoutMain from '../../components/LayoutMain';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import spanishFlag from '../../assets/media/flags/spanish_flag.jpg';
import englishFlag from '../../assets/media/flags/english_flag.jpg';

function PerfilPage() {
    const token = localStorage.getItem('jwtToken');
    const { perfilId } = useParams(); // Obtiene el ID del usuario de la URL
    const [perfil, setPerfil] = useState({
        nombre: '',
        name: '',
        resumen: '',
        brief: '',
        ficha_tecnica: '',
        propiedad: '',
        etapa: '',
        inversion: '',
        mano_obra: '',
        obras_principales: '',
        maquinaria: '',
        proyectos_relacionados: '',
        area_influencia: '',
        otros: '',
        contacto: '',
        funcionarios: '',
        datasheet: '',
        owners: '',
        phase: '',
        investment: '',
        workers: '',
        mainworks: '',
        equipment: '',
        projectsrelated: '',
        areainfluence: '',
        others: '',
        contact: '',
        executives: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (perfilId) {
            axios.get(`https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraPerfil/${perfilId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
                },
            })
                .then(response => {
                    if (response.data.success) {
                        const perfilData = response.data.datos;
                        setPerfil(perfilData);
                    } else {
                        toast.error(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error al obtener el perfil:', error);
                    toast.error('Error al obtener el perfil');
                });
        }
    }, [perfilId]);

    const handleEditorChange = (name, event, editor) => {
        const data = editor.getData();
        setPerfil(prevState => ({
            ...prevState,
            [name]: data
        }));
    };

    const handleInputChange = (name, event) => {
        const value = event.target.value;
        setPerfil(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const endpoint = perfilId
            ? `https://api.ptpbizintelligence.com/api/web/admin/infraestructura/infraestructuraUpdatePerfil/${perfilId}` // Endpoint de actualización
            : 'https://api.ptpbizintelligence.com/api/web/admin-infraestructura/infraestructura-create-perfil'; // Endpoint de creación

        const method = perfilId ? 'put' : 'post';

        try {
            await axios[method](endpoint, perfil, {
                headers: {
                    Authorization: `Bearer ${token}` // Añadir el token JWT en el encabezado Authorization
                },
            });
            const mensaje = perfilId ? 'Perfil actualizado con éxito' : 'Perfil creado con éxito';
            toast.success(mensaje);
        } catch (error) {
            console.error('Error al guardar el perfil:', error);
            toast.error('Error al guardar el perfil');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderField = (labelEs, labelEn, nameEs, nameEn, isTextArea = true) => (
        <div className="fv-row mb-7">
            <label className="fs-6 fw-semibold form-label mt-3">
                <span>{labelEs} <img src={spanishFlag} alt="Español" style={{ width: '20px', marginLeft: '5px' }} /></span>
            </label>
            {isTextArea ? (
                <CKEditor
                    editor={ClassicEditor}
                    data={perfil[nameEs]}
                    onChange={(event, editor) => handleEditorChange(nameEs, event, editor)}
                />
            ) : (
                <input
                    type="text"
                    className="form-control"
                    value={perfil[nameEs]}
                    onChange={(event) => handleInputChange(nameEs, event)}
                />
            )}
            <label className="fs-6 fw-semibold form-label mt-3">
                <span>{labelEn} <img src={englishFlag} alt="Inglés" style={{ width: '20px', marginLeft: '5px' }} /></span>
            </label>
            {isTextArea ? (
                <CKEditor
                    editor={ClassicEditor}
                    data={perfil[nameEn]}
                    onChange={(event, editor) => handleEditorChange(nameEn, event, editor)}
                />
            ) : (
                <input
                    type="text"
                    className="form-control"
                    value={perfil[nameEn]}
                    onChange={(event) => handleInputChange(nameEn, event)}
                />
            )}
        </div>
    );

    return (
        <LayoutMain title="Perfil">
            <ToastContainer />
            <div id="kt_app_toolbar" className="app-toolbar pt-10 mb-0">
                <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex align-items-stretch">
                    <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">
                        <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">
                            <h1 className="page-heading d-flex flex-column justify-content-center text-gray-900 fw-bold fs-3 m-0">
                                {perfilId ? 'Editar Perfil' : 'Agregar Perfil'}
                            </h1>
                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                                <li className="breadcrumb-item text-muted">Infraestructura</li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">
                                    <Link to="/infraestructura-perfiles">Perfiles</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted">Perfil</li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <Link to="/infraestructura-perfiles" className="btn btn-sm btn-flex btn-outline btn-active-primary bg-body">
                                <i className="ki-outline ki-black-left fs-4"></i>Regresar
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-fluid">
                    <div className="row g-7">
                        <div className="col-xl-10 col-xxl-10">
                            <div className="card card-flush h-lg-100" id="kt_contacts_main">
                                <div className="card-body pt-5">
                                    <form onSubmit={handleSubmit} className="form">
                                        {renderField('Nombre', 'Name', 'nombre', 'name', false)}
                                        <hr />
                                        {renderField('Resumen', 'Brief', 'resumen', 'brief')}
                                        <hr />
                                        {renderField('Ficha Técnica', 'Datasheet', 'ficha_tecnica', 'datasheet')}
                                        <hr />
                                        {renderField('Propiedad', 'Owners', 'propiedad', 'owners')}
                                        <hr />
                                        {renderField('Etapa', 'Phase', 'etapa', 'phase')}
                                        <hr />
                                        {renderField('Inversión', 'Investment', 'inversion', 'investment')}
                                        <hr />
                                        {renderField('Mano de Obra', 'Workers', 'mano_obra', 'workers')}
                                        <hr />
                                        {renderField('Obras Principales', 'Main Works', 'obras_principales', 'mainworks')}
                                        <hr />
                                        {renderField('Maquinaria', 'Equipment', 'maquinaria', 'equipment')}
                                        <hr />
                                        {renderField('Proyectos Relacionados', 'Projects Related', 'proyectos_relacionados', 'projectsrelated')}
                                        <hr />
                                        {renderField('Área de Influencia', 'Area Influence', 'area_influencia', 'areainfluence')}
                                        <hr />
                                        {renderField('Otros', 'Others', 'otros', 'others')}
                                        <hr />
                                        {renderField('Datos de Contacto', 'Contact Data', 'contacto', 'contact')}
                                        <hr />
                                        {renderField('Funcionarios', 'Executives', 'funcionarios', 'executives')}
                                        <div className="d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light me-3">Cancelar</button>
                                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <>
                                                        Por favor espera...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </>
                                                ) : (
                                                    "Guardar"
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutMain>
    );
}

export default PerfilPage;
